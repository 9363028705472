import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { FormattedMessage, IntlContext } from 'react-intl'
import {
  parseISO,
  isToday,
  differenceInWeeks,
  differenceInMonths,
  isBefore,
  isAfter,
  addDays,
  getUnixTime
} from 'date-fns'
//
import Layout from '~components/layout'
import SEO from '~components/seo'
import NewsfeedCard from '~storybook/newsfeed'
import { SearchLayout } from '~storybook/layout/index'
import Filters from '~storybook/filters/index'
import { Heading } from '~storybook/blocks/typography/index'
import Button from '~storybook/button/index'
import Pagination from '~storybook/pagination'
import { getUrlParameter } from '~utils/common'

const LIMIT = 5

const NewsFeedPage = ({ data, pageContext, location }) => {
  const intl = React.useContext(IntlContext)
  const initialPage = parseInt(getUrlParameter(location?.href, 'page') || 1, 10)

  const [page, setPage] = useState(initialPage)
  const { language, menus, metatags } = pageContext
  const { group, allNews } = data.drupal
  const {
    fieldGdprTitle,
    fieldGdprDescription,
    fieldAnnouncement,
    fieldMiniGdprAnnouncement,
    fieldSearchTopics = [],
    fieldGdprCookies = [],
    fieldMetaNewsTitle,
    fieldMetaNewsDescription,
    fieldMetaSiteImage,
    fieldNewsBlacklist
  } = group
  const langCode = language.toLowerCase()
  const langMenus = menus[langCode] || {}
  const [filters, setFilters] = useState(false)
  const [originalNews, setOriginalNews] = useState([])
  const [filteredNews, setFilteredNews] = useState(false)
  const [filterKey, setFilterKey] = useState('search-key_0')
  const [filterOptions, setFilterOptions] = useState([])

  useEffect(() => {
    const news = allNews?.reverseGidGroupContent?.entities
      ?.map((entity) => entity?.queryEntityId)
      ?.map((entities) => entities?.entities?.[0])
      ?.map((entity) => entity?.entityTranslations?.find((trans) => trans?.langcode?.value === langCode))
      ?.filter((entity) => entity?.nid)
      ?.filter((entity) => entity?.langcode?.value === langCode)
      ?.filter((entity) => {
        const blackListIds = fieldNewsBlacklist?.map((term) => term?.entity?.tid) || []
        const newsTopicIds = entity?.fieldNewsTopics?.map((topic) => topic?.entity?.tid) || []

        return !newsTopicIds?.some((topic) => blackListIds.includes(topic))
      })
      ?.map((entity) => {
        const newsSources = entity?.fieldNewsSource?.map((source) => {
          return { id: `${source?.entity?.tid}`, name: source?.entity?.name }
        })

        const newsTopics = entity?.fieldNewsTopics?.map((topic) => {
          return { id: `${topic?.entity?.tid}`, name: topic?.entity?.name }
        })

        return {
          id: entity?.nid,
          title: entity?.title,
          originalDate: entity?.fieldNewsDate?.value,
          timestamp: getUnixTime(new Date(entity?.fieldNewsDate?.value)),
          date: entity?.fieldNewsDate?.value
            ? parseISO(entity?.fieldNewsDate?.value, 'yyyy-MM-dd HH:mm', new Date())
            : null,
          image: { url: entity?.fieldEntityImage?.entity?.thumbnail?.url },
          text: entity?.fieldNewsSummary,
          cta: {
            url:
              entity?.fieldNewsSourceLink?.uri ||
              `/${langCode}${entity?.path?.alias}` ||
              `/${langCode}/node/${entity?.nid}`
          },
          newsSources,
          newsTopics,
          category: entity?.fieldTypeLabel || intl.formatMessage({ id: 'news.news' })
        }
      })

    /* eslint-disable no-nested-ternary */
    news.sort((a, b) => (a?.timestamp < b?.timestamp ? 1 : b?.timestamp < a?.timestamp ? -1 : 0))
    /* eslint-disable no-nested-ternary */

    const topics = fieldSearchTopics
      ?.map((entity) => entity?.entity)
      .map((entity) => {
        return {
          id: `${entity?.entityId}`,
          name: entity?.name
        }
      })

    const sourcesKeys = []
    const sources = []
    /* eslint-disable no-unused-expressions, consistent-return */
    news?.forEach((entity) => {
      entity?.newsSources?.forEach((source) => {
        if (!sourcesKeys.includes(source?.id)) {
          sourcesKeys.push(source?.id)
          sources.push({ id: `${source?.id}`, name: source?.name })
        }
      })
    })
    /* eslint-enable no-unused-expressions, consistent-return */

    setOriginalNews(news)
    const initialFilterOptions = []

    if (topics?.length) {
      initialFilterOptions.push({
        id: 'topics',
        type: 'checkbox',
        label: intl.formatMessage({ id: 'filter.topics' }),
        options: topics?.map((topic) => {
          return {
            name: `${topic?.id}`,
            label: topic?.name
          }
        })
      })
    }

    if (sources?.length) {
      initialFilterOptions.push({
        id: 'sources',
        type: 'checkbox',
        label: intl.formatMessage({ id: 'filter.sources' }),
        options: sources?.map((source) => {
          return {
            name: `${source?.id}`,
            label: source?.name
          }
        })
      })
    }

    initialFilterOptions.push({
      id: 'dates',
      type: 'radio',
      label: intl.formatMessage({ id: 'filter.dates' }),
      options: [
        {
          name: 'all',
          label: intl.formatMessage({ id: 'filter.all' })
        },
        {
          name: 'today',
          label: intl.formatMessage({ id: 'filter.today' })
        },
        {
          name: 'currentweek',
          label: intl.formatMessage({ id: 'filter.currentWeek' })
        },
        {
          name: 'pastweek',
          label: intl.formatMessage({ id: 'filter.pastWeek' })
        },
        {
          name: 'last3months',
          label: intl.formatMessage({ id: 'filter.last3Months' })
        },
        {
          name: 'last6months',
          label: intl.formatMessage({ id: 'filter.last6Months' })
        },
        {
          name: 'lastyear',
          label: intl.formatMessage({ id: 'filter.lastYear' })
        },
        {
          name: 'range',
          label: intl.formatMessage({ id: 'filter.specificDates' }),
          showCalendarRange: true
        }
      ]
    })

    setFilterOptions(initialFilterOptions)
  }, [allNews])

  useEffect(() => {
    if (filters) {
      let newsToFilter = [...originalNews]

      if (filters.str) {
        newsToFilter = newsToFilter.filter((item) => {
          return (
            item?.title?.toLowerCase()?.includes(filters?.str?.toLowerCase()) ||
            item?.text?.toLowerCase()?.includes(filters?.str?.toLowerCase())
          )
        })
      }

      const selectedSources = filters?.filters
        ?.filter((filter) => filter.id === 'sources')?.[0]
        ?.options?.filter((opt) => opt.checked)
        .map((opt) => opt?.name)
      const selectedTopics = filters?.filters
        ?.filter((filter) => filter.id === 'topics')?.[0]
        ?.options?.filter((opt) => opt.checked)
        .map((opt) => opt?.name)
      const selectedDates = filters?.filters
        ?.filter((filter) => filter.id === 'dates')?.[0]
        ?.options?.filter((opt) => opt.checked)
        .map((opt) => opt?.name)

      const newFilteredNews = newsToFilter?.filter((item) => {
        let valid = true
        if (valid && Array.isArray(selectedSources) && selectedSources.length > 0) {
          valid = item?.newsSources?.some((source) => selectedSources?.includes(`${source?.id}`))
        }
        if (valid && Array.isArray(selectedTopics) && selectedTopics.length > 0) {
          valid = item?.newsTopics?.some((topic) => selectedTopics?.includes(`${topic?.id}`))
        }
        if (valid && Array.isArray(selectedDates) && selectedDates.length > 0) {
          if (selectedDates[0] === 'all') {
            return true
          }
          if (selectedDates[0] === 'today') {
            valid = isToday(new Date(item?.originalDate))
          }
          if (selectedDates[0] === 'currentweek') {
            const diff = differenceInWeeks(new Date(), new Date(item?.originalDate))
            valid = diff === 0
          }
          if (selectedDates[0] === 'pastweek') {
            const diff = differenceInWeeks(new Date(), new Date(item?.originalDate))
            valid = diff === 1
          }
          if (selectedDates[0] === 'last3months') {
            const diff = differenceInMonths(new Date(), new Date(item?.originalDate))
            valid = diff < 3
          }
          if (selectedDates[0] === 'last6months') {
            const diff = differenceInMonths(new Date(), new Date(item?.originalDate))
            valid = diff < 6
          }
          if (selectedDates[0] === 'lastyear') {
            const diff = differenceInMonths(new Date(), new Date(item?.originalDate))
            valid = diff < 12
          }
          if (selectedDates[0] === 'range') {
            let isValidRange = true
            if (isValidRange && filters.startDate) {
              isValidRange = isAfter(new Date(item?.originalDate), new Date(filters.startDate))
            }
            if (isValidRange && filters.endDate) {
              isValidRange = isBefore(new Date(item?.originalDate), addDays(new Date(filters.endDate), 1))
            }

            return isValidRange
          }
        }

        return valid
      })
      setFilteredNews(newFilteredNews)
      setPage(1)
    }
  }, [filters])

  useEffect(() => {
    window.history.pushState('', document.title, `${location?.pathname}?page=${page}`)
    // eslint-disable-next-line no-unused-expressions
    document.getElementById('gatsby-focus-wrapper')?.scrollIntoView({ behavior: 'smooth' })
  }, [page])

  const newsToUse = filteredNews === false ? originalNews : filteredNews

  const resetFilters = () => {
    setFilteredNews(false)
    setFilters(false)
    setFilterKey(`search-key_${parseInt(filterKey?.split('_')?.[1], 10) + 1}`)
    setPage(1)
  }

  const filterSection = (
    <div className="mb-6">
      <Heading className="lg:hidden mb-6" level="h1">
        {intl.formatMessage({ id: 'news.news' })}
      </Heading>
      {filterOptions ? (
        <Filters
          key={filterKey}
          {...{
            filters: filterOptions,
            onSearch: (str) => {
              setFilters({ ...filters, str })
            },
            onFilter: (newFilters) => {
              setFilters({ ...filters, ...newFilters })
            }
          }}
        />
      ) : null}
      <div className="mt-4">
        {filteredNews !== false && (
          <Button icon="Close" variant="tertiary" onClick={resetFilters}>
            {intl.formatMessage({ id: 'filter.resetFilters' })}
          </Button>
        )}
      </div>
    </div>
  )

  const total = newsToUse?.length || 0
  const start = page * LIMIT - LIMIT
  const end = (page - 1) * LIMIT + LIMIT

  const availableNews = newsToUse?.slice(start, end)

  const resultsSection = (
    <div>
      <div className="flex flex-row items-center justify-between">
        <Heading className="hidden lg:block" level="h1">
          {intl.formatMessage({ id: 'news.news' })}
        </Heading>
        {filteredNews !== false && newsToUse?.length > 0 && <FormattedMessage id="search.results" values={{ total }} />}
      </div>
      {filteredNews !== false && newsToUse?.length === 0 && <FormattedMessage id="search.results" values={{ total }} />}
      <div className="mt-10">
        {availableNews?.map((entry) => (
          <div key={`news-${entry.id}`}>
            <div className="mb-10">
              <NewsfeedCard {...entry} />
            </div>
            <hr className="mb-12" />
          </div>
        ))}
      </div>

      <Pagination page={page} total={total} limit={LIMIT} onPageChange={(pnumber) => setPage(pnumber)} />
    </div>
  )

  const newMetatags = [...metatags]

  newMetatags.push({
    __typename: 'MetaValue',
    key: 'title',
    value: fieldMetaNewsTitle
  })

  newMetatags.push({
    __typename: 'MetaValue',
    key: 'description',
    value: fieldMetaNewsDescription
  })

  newMetatags.push({
    __typename: 'MetaValue',
    key: 'twitter:description',
    value: fieldMetaNewsDescription
  })

  return (
    <Layout
      language={langCode}
      menus={langMenus}
      announcement={fieldAnnouncement}
      fieldCss={ group.fieldCss }
      cookiesInfo={{ fieldGdprTitle, fieldGdprDescription, fieldGdprCookies }}
      miniGdprAnnouncement={fieldMiniGdprAnnouncement}
    >
      <SEO
        key={`newsfeed-${langCode}`}
        title="News"
        lang={langCode}
        metatags={newMetatags}
        globalImage={fieldMetaSiteImage?.entity?.thumbnail?.url}
      />
      <SearchLayout search={filterSection} results={resultsSection} />
    </Layout>
  )
}

export default NewsFeedPage

export const pageQuery = graphql`
  query($gid: String!, $language: Drupal_LanguageId!) {
    drupal {
      ...FragmentLangs
      group: groupById(id: $gid, language: $language) {
        ...FragmentSite
      }
      allNews: groupById(id: $gid, language: $language) {
        reverseGidGroupContent(
          limit: 50000
          filter: { conditions: { field: "type.target_id", value: "website-group_node-news" } }
        ) {
          entities {
            ... on Drupal_GroupContentWebsiteGroupNodeNews {
              queryEntityId(filter: { conditions: { field: "field_type", value: "news" } }) {
                entities {
                  entityTranslations {
                    ... on Drupal_NodeNews {
                      langcode {
                        value
                      }
                      title
                      fieldTypeLabel
                      nid
                      fieldNewsSource {
                        entity {
                          tid
                          name
                        }
                      }
                      fieldEntityImage {
                        entity {
                          ...FragmentImage
                        }
                      }
                      fieldNewsDate {
                        date
                        value
                      }
                      fieldNewsTopics {
                        entity {
                          tid
                          name
                        }
                      }
                      fieldNewsSummary
                      fieldNewsLinkToSource
                      fieldNewsSourceLink {
                        uri
                        title
                      }
                      path {
                        alias
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
